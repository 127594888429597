import { Space, Table, Tag } from 'antd';
import React from 'react'

export default function brandList() {
    const columns = [
        {
            title: 'Brands Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>Delete</a>
                </Space>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            name: 'John Brown',
        },
        {
            key: '2',
            name: 'Jim Green',
        },
        {
            key: '3',
            name: 'Joe Black',
        },
        {
            key: '4',
            name: 'John Brown',
        },
        {
            key: '5',
            name: 'John Brown',
        },

    ];
    return (
        <div className='pagiTable'>
            <h2>Brand List</h2>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
    )
}

import { AlertOutlined, UserAddOutlined, UsergroupAddOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import React, { useEffect } from 'react'
import UserList from '../../adminComponents/adminCompany/userList'
import BrandList from '../../adminComponents/adminCompany/brandList'
import PackageDetail from '../../adminComponents/adminCompany/packageDetail'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Companies_Details } from '../../redux/SuperAdmin/SuperAdminActions';

export default function Details() {
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const CompaniesDetailsData = useSelector((state) => state.SuperAdminReducer.CompaniesDetailsData);
    const isCompaniesDetailsLoading = useSelector((state) => state.SuperAdminReducer.isCompaniesDetailsLoading);
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        let data = {
            _id: userData?._id,
            companyID: location?.state?.data
        }
        dispatch(Get_Companies_Details(data, usertoken))
    }, [])

    return (
        <div style={{ margin: '15px 0px' }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 12 }}>
                <Col span={8}>
                    <Card className='dashboardCard'>
                        <div className='box'>
                            <div className='boxContent'>
                                <h3>User Count</h3>
                                <h2>1</h2>
                            </div>
                            <div className='boxIcon'>
                                <UserAddOutlined style={{ fontSize: '28px' }} />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col span={8} style={{ paddingRight: '8px' }}>
                    <Card className='dashboardCard served'>
                        <div className='box'>
                            <div className='boxContent'>
                                <h3>Brand Count</h3>
                                <h2>1</h2>
                            </div>
                            <div className='boxIcon'>
                                <UserSwitchOutlined style={{ fontSize: '28px' }} />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col span={8} style={{ paddingLeft: '0px' }}>
                    <Card className='dashboardCard ticket'>
                        <div className='box'>
                            <div className='boxContent'>
                                <h3>Ticket Counts</h3>
                                <h2>1</h2>
                            </div>
                            <div className='boxIcon'>
                                <AlertOutlined style={{ fontSize: '28px' }} />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 12 }}>
                <Col span={16}>
                    <UserList />
                    <BrandList />
                </Col>
                <Col span={8}>
                    <PackageDetail />
                </Col>
                <Col span={12} style={{ paddingLeft: '0px' }}>
                </Col>
            </Row>
        </div>
    )
}

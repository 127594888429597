import { combineReducers } from "redux";
import AuthReducer from "./Auth/AuthReducer";
import BrandsReducer from "./Brands/BrandsReducer";
import UsersReducer from "./Users/UsersReducer";
import VisitorChatsReducer from "./VisitorChats/VisitorChatsReducer";
import ClientReducer from "./Clients/ClientsReducer";
import PastChatReducer from "./Chats/PastChatReducer";
import ShortcutsReducer from "./Shortcuts/ShortcutsReducer";
import TriggersReducer from "./Triggers/TriggersReducer";
import WidgetReducer from "./Widget/WidgetReducer";
import HistoryReducer from "./History/HistoryReducer";
import OfflineStatusReducer from "./OfflineStatus/OfflineStatusReducer";
import TicketsReducer from "./Tickets/TicketsReducer";
import BannedReducer from "./Banned/BannedReducer";
import ProfileReducer from "./Profile/ProfileReducer";
import ALLHistoryReducer from "./AllHistory/AllHistoryReducer";
import AnalyticsReducer from "./Analytics/AnalyticsReducer";
import DepartmentReducer from "./Departments/DepartmentsReducer";
import PackagesReducer from "./Packages/PackagesReducer";
import CompanyReducer from "./Companies/companyReducer";
import VisitorReducer from "./VisitorsJoined/VisitorsJoinedReducer";
import SuperAdminReducer from "./SuperAdmin/SuperAdminReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Brands: BrandsReducer,
  Users: UsersReducer,
  Visitor: VisitorChatsReducer,
  Client: ClientReducer,
  PastChat: PastChatReducer,
  Shortcuts: ShortcutsReducer,
  Triggers: TriggersReducer,
  WidgetReducer: WidgetReducer,
  HistoryReducer: HistoryReducer,
  OfflineStatusReducer: OfflineStatusReducer,
  TicketsReducer: TicketsReducer,
  BannedReducer: BannedReducer,
  ProfileReducer: ProfileReducer,
  ALLHistoryReducer: ALLHistoryReducer,
  AnalyticsReducer: AnalyticsReducer,
  Departments: DepartmentReducer,
  PackagesReducer: PackagesReducer,
  CompanyReducer: CompanyReducer,
  VisitorReducer: VisitorReducer,
  SuperAdminReducer: SuperAdminReducer
});

export default rootReducer;

import React from 'react'

export default function packageDetail() {
    return (
        <div className='pagiTable'>
            <h2>Package Details</h2>
            <ul>
                <li>Company ID: <span> 123 </span></li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5 </li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9 </li>
                <li>10</li>
                <li>11</li>
                <li>12</li>
            </ul>
        </div >
    )
}

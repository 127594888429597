//  Local
// export const BASE_URL_SOCKET = "http://localhost:8001";
// export const BASE_URL = "http://localhost:8000";
//  Staging
// export const BASE_URL = "https://apigateway.convobird.com";
// export const BASE_URL_SOCKET = "https://agentservices.convobird.com";
// Live
export const BASE_URL = "https://web.convobird.com";
export const BASE_URL_SOCKET = "https://agent.convobird.com";

// export const BASE_URL_SOCKET = "http://172.16.108.205:8001";
// export const BASE_URL = "http://172.16.108.205:8000";
    
import React from 'react'
import { Avatar, List } from 'antd';


export default function userList() {
    const data = [
        {
            title: 'Ant Design Title 1',
        },
        {
            title: 'Ant Design Title 2',
        },
        {
            title: 'Ant Design Title 3',
        },
        {
            title: 'Ant Design Title 4',
        },
        {
            title: 'Ant Design Title 4',
        },
        {
            title: 'Ant Design Title 4',
        },
    ];
    return (
        <div className='pagiTable'>
            <h2>User List</h2>
            <List
                itemLayout="horizontal"
                dataSource={data}
                style={{ height: '290px', overflowY: 'scroll' }}
                renderItem={(item, index) => (
                    <List.Item style={{ padding: '9px 0px' }}>
                        <List.Item.Meta
                            avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                            title={<a href="https://ant.design">{item.title}</a>}
                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}

import * as actionTypes from "./SuperAdminTypes";
import { Get, Post } from "../../helpers/apicalls/apicalls";
import { message } from "antd";

export const Get_Companies = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_COMPANIES_LOADING });
    Post("/agents/user/admin/getCompanies", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: actionTypes.GET_COMPANIES_SUCCESS,
            payload: response?.data,
          });
        } else {
          dispatch({ type: actionTypes.GET_COMPANIES_FAILED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.GET_COMPANIES_FAILED });
      });
  };
};

export const Get_Companies_Details = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_COMPANIES_DETAILS_LOADING });
    Post("/agents/user/admin/getCompaniesDetails", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: actionTypes.GET_COMPANIES_DETAILS_SUCCESS,
            payload: response?.data,
          });
        } else {
          dispatch({ type: actionTypes.GET_COMPANIES_DETAILS_FAILED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.GET_COMPANIES_DETAILS_FAILED });
      });
  };
};


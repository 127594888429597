import * as actionTypes from "./SuperAdminTypes";

const INTIAL_STATE = {
  CompaniesData: null,
  isCompaniesLoading: false,

  CompaniesDetailsData: null,
  isCompaniesDetailsLoading: false,
};

const SuperAdminReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.GET_COMPANIES_LOADING:
      return {
        ...state,
        isCompaniesLoading: true
      };
    case actionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        CompaniesData: payload,
        isCompaniesLoading: false
      };
    case actionTypes.GET_COMPANIES_FAILED:
      return {
        ...state,
        isCompaniesLoading: false
      };
    ///////////////////////////////////////////////////////////////////////////////////////
    case actionTypes.GET_COMPANIES_DETAILS_LOADING:
      return {
        ...state,
        isCompaniesDetailsLoading: true
      };
    case actionTypes.GET_COMPANIES_DETAILS_SUCCESS:
      return {
        ...state,
        CompaniesDetailsData: payload,
        isCompaniesDetailsLoading: false
      };
    case actionTypes.GET_COMPANIES_DETAILS_FAILED:
      return {
        ...state,
        isCompaniesDetailsLoading: false
      };

    default:
      return state;
  }
};

export default SuperAdminReducer;

import { Card, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import logo1 from "../../assets/image/olive.png"
import logo2 from "../../assets/image/trango.webp"
import { Link, useNavigate } from 'react-router-dom'
import { Input, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { Get_Companies } from '../../redux/SuperAdmin/SuperAdminActions'


export default function Company() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);

    const companiesData = useSelector((state) => state.SuperAdminReducer.CompaniesData);
    const CompaniesLoading = useSelector((state) => state.SuperAdminReducer.isCompaniesLoading);
    console.log(companiesData, "companiesData")

    useEffect(() => {
        let data = {
            _id: userData?._id
        }
        dispatch(Get_Companies(data, usertoken))
    }, [])


    const { Search } = Input;

    const onSearch = (value, _e, info) => console.log(info?.source, value);


    return (
        <>
            <div className='companies'>
                <div className='headers'>
                    <h2>All Companies</h2>
                    <Search
                        placeholder="search company"
                        onSearch={onSearch}
                        style={{
                            width: 200,
                        }}
                    />
                </div>
                <Row>
                    {companiesData?.map((item, index) => {
                        return <Col key={index} span={6}>
                            <div className="companyCard">
                                <div className="imgBx">
                                    <img src={logo1} alt="nike-air-shoe" />
                                </div>
                                <div className="contentBx">
                                    <h2>{item?.title}</h2>
                                    <a className='detailBtn' onClick={() => navigate('/company/details', { state: { data: item?._id } })}>View Details</a>
                                    {/* <Link to="/company/details" className='detailBtn'>View Details</Link> */}
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>
            </div>
        </>
    )
}
